import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/back.png";

import { UserAuth } from "context/AuthContext";
import MDButton from "components/MDButton";
import { Divider } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";

function Header({ children, profile, email, name }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const { user } = UserAuth();

  return (
    <>
      <MDBox
        position="relative"
        mb={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Card
          sx={{
            position: "relative",
            mt: 0,
            py: 2,
            px: 3,
            width: "100%",
          }}
        >
          {profile ? (
            <>
              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="space-between"
                px={5}
                pt={3}
              >
                <MDBox display="flex" alignItems="center" mb={{ xs: 0, md: 0, xl: 0 }} mt={2}>
                  <Grid item>
                    <MDAvatar
                      src={burceMars}
                      alt="profile-image"
                      size="xxl"
                      shadow="sm"
                      variant="square"
                      sx={{ borderRadius: 3 }}
                    />
                  </Grid>
                  <Grid item>
                    <MDBox height="100%" ml={2} lineHeight={1}>
                      <MDTypography variant="h5" fontWeight="medium" color="black">
                        {name}
                      </MDTypography>
                      <MDTypography variant="button" color="black" fontWeight="regular">
                        {email}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </MDBox>
              </Grid>
            </>
          ) : (
            <></>
          )}
          {children}
        </Card>
      </MDBox>
    </>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
