import Carousel from "react-material-ui-carousel";
import CarouselCard from "./carouselCard";
import MDBox from "components/MDBox";

function BannerCarousel({ banners }) {
  return (
    <MDBox>
      <Carousel
        interval={5000}
        animation="slide"
        indicators={false}
        navButtonsAlwaysInvisible={true}
      >
        {banners.map((banner) => (
          <CarouselCard key={banner.id} url={banner.url} image={banner.bannerImage} />
        ))}
      </Carousel>
    </MDBox>
  );
}

export default BannerCarousel;
