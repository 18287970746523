import { useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import SidenavCollapse from "common/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "common/Sidenav/SidenavRoot";
import sidenavLogoLabel from "common/Sidenav/styles/sidenav";

import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  // setTransparentSidenav,
  // setWhiteSidenav,
} from "context";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import SidenavSubmenu from "./SidenavSubmenu";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1550,
    },
  },
  palette: {
    primary: {
      main: "#9BE06A",
      darker: "#9BE06A",
    },
  },
});

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const { t } = useTranslation();

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      // setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      // setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route, tabType, nestedItems }) => {
      let returnValue;
      let allowedTabs = JSON.parse(secureLocalStorage.getItem("current-user-data"));

      if (allowedTabs) {
        allowedTabs = allowedTabs["uiAccess"];
        if (!allowedTabs || (tabType != "open" && !allowedTabs[tabType])) {
          returnValue = null;
        } else {
          if (type === "collapse") {
            returnValue = href ? (
              <Link
                href={href}
                key={key}
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: "none" }}
              >
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  noCollapse={noCollapse}
                />
              </Link>
            ) : (
              <NavLink key={key} to={route}>
                <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
              </NavLink>
            );
          } else if (type === "submenu") {
            returnValue = (
              <SidenavSubmenu
                key={key}
                textColor={textColor}
                name={name}
                nestedItems={nestedItems}
              />
            );
          } else if (type === "title") {
            returnValue = (
              <MDTypography
                key={key}
                color={textColor}
                display="block"
                variant="caption"
                fontWeight="bold"
                textTransform="uppercase"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                {title}
              </MDTypography>
            );
          } else if (type === "divider") {
            returnValue = (
              <Divider
                key={key}
                light={
                  (!darkMode && !whiteSidenav && !transparentSidenav) ||
                  (darkMode && !transparentSidenav && whiteSidenav)
                }
              />
            );
          }
        }
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox
          component={NavLink}
          to="/announcements"
          display="flex"
          alignItems="center"
          justifyContent="center"
          mx={1}
        >
          {brand && (
            <MDBox
              component="img"
              src={!brand ? "./placeholder.jpg" : brand}
              alt="Brand"
              width="112px"
            />
          )}
        </MDBox>
      </MDBox>
      {/* <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      /> */}
      <List>{renderRoutes}</List>

      {/* <MDBox 
      textAlign="center"
      mt={7}
      mb={3}
      ml={3} 
      mr={3}
      sx={{ 
        p: 3, 
        border: '0px solid gray',
        borderRadius:'10px',
        Color:'white',
        boxShadow :'0px 0px 3px gray' }}>
          
       <MDTypography color={textColor} fontSize={14}>{t('sidebar.help')}</MDTypography>

       <MDBox sx={{fontSize:40, marginBottom:-1, Color:"white"}}>
        <SupportAgentIcon  color={textColor} sx={{opacity:0.5}}/>
        </MDBox>

        <MDButton
          size="small"
          component="a"
          target="_blank"
          variant="gradient"
          color="info"
          sx={{py:0, fontSize:8}}
        >
          {t('sidebar.talkwithus')}
        </MDButton>
      </MDBox> */}
      {/* <ThemeProvider theme={theme}>
        <MDBox
          textAlign="center"
          mt={10}
          mb={1}
          sx={{
            border: "0px solid gray",
            borderRadius: "10px",
            color: "white",
            position: { xxl: "absolute", xl: "relative", lg: "relative" },
            mt: { xxl: 5, xl: 5, lg: 5 },
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <MDTypography color={textColor} fontSize={14}>
            {t("sidebar.follow")}
          </MDTypography>

          <MDBox
            component="a"
            href="https://www.linkedin.com/company/freyt-world/"
            target="_blank"
            sx={{ fontSize: 24, Color: "white", cursor: "pointer" }}
          >
            <LinkedInIcon color="primary" />
          </MDBox>
        </MDBox>
      </ThemeProvider> */}
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
