import * as yup from "yup";

const fileNameRules = /^[A-Za-z0-9\-_ ]+$/;
const urlRules = /^(https?:\/\/).*/;

export const editBannerSchema = yup.object().shape({
  title: yup
    .string()
    .matches(fileNameRules, {
      message: "Title can only include letters, numbers, dashes and underscores",
    })
    .max(254, "Content is too large")
    .required("Title is required"),
  // description: yup.string().max(254, "Content is too large").required("Description is required"),
  url: yup
    .string()
    .max(1499, "Content is too large")
    .matches(urlRules, "Enter correct url with 'https://'"),
  cities: yup.string(),
  countries: yup.array(),
  // tags: yup.string(),
  // labels: yup.string(),
  priority: yup
    .number()
    .min(1, "Priority must be between 1 and 10")
    .max(10, "Priority must be between 1 and 10")
    .typeError("Priority must be a number")
    .required("Priority is required"),
  expiryDate: yup.string().required("Expiry Date is required"),
  publishDate: yup.string().required("Date To Publish is required"),
  file: yup
    .mixed()
    .nullable()
    .test("fileSize", "Please upload a file below 50MB.", (value) => {
      if (value) {
        return value.size <= 50000000;
      }
      return true;
    }),
});
