// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";

// Overview page components
import Header from "layouts/profile/components/Header";

// Images
import { Card, Chip } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { getAllMembers } from "api_services/MemberService";
import { ROLE_ACCESS } from "utils/constants/roles.constants";
import CompanyCard from "common/Cards/ProjectCards/companyCard";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import secureLocalStorage from "react-secure-storage";
import { addFavouriteMarker } from "layouts/dashboard";
import { getListOfMembersFromId } from "api_services/MemberService";

function getCurrentDate() {
  let date = new Date();
  // let dd = String(date.getDate()).padStart(2, "0");
  // let mm = String(date.getMonth() + 1).padStart(2, "0");
  // let yyyy = String(date.getFullYear());
  return date.getTime();
  // return yyyy + "-" + mm + "-" + dd;
  // return a date in the form yyyy-mm-dd
}

function FavouriteMembers() {
  const editor = JSON.parse(secureLocalStorage.getItem("current-user-data"))[
    ROLE_ACCESS.EDIT_ACCESS
  ];
  const userFavourites = JSON.parse(secureLocalStorage.getItem("user-favourites"));
  // const filters = [
  //   {
  //     value: "memberContacts.displayName",
  //     title: "Personnel",
  //   },
  //   {
  //     value: "companyName",
  //     title: "Companies",
  //   },
  //   {
  //     value: "exportCountries",
  //     title: "Export Countries",
  //   },
  //   {
  //     value: "importCountries",
  //     title: "Import Countries",
  //   },
  //   {
  //     value: "services.name",
  //     title: "Services",
  //   },
  //   {
  //     value: "carriers.name",
  //     title: "Carriers",
  //   },
  //   {
  //     value: "airlines.name",
  //     title: "Airlines",
  //   },
  //   {
  //     value: "marketsToDevelop",
  //     title: "Markets Looking to Develop In",
  //   },
  // ];
  const [memberData, setMemberData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [nextPage, setNextPage] = useState(false);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedFilters, setSelectedFilters] = useState([]);
  const userToken = secureLocalStorage.getItem("custom-token");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchData.length >= 3) {
      getAllMemberList();
    } else {
      getAllFavouriteMemberList();
    }
  };

  async function getAllMemberList() {
    // const filterList = selectedFilters.length > 0 ? selectedFilters : null;
    const filterList = [
      "companyName",
      "memberContacts.displayName",
      "address.country",
      "address.city",
    ];
    const response = await getAllMembers(
      searchData,
      filterList,
      userToken,
      null,
      editor,
      page,
      100
    );
    if (response !== null) {
      const members = response.searchResults;
      if (!editor) {
        addFavouriteMarker(members, userFavourites, "id");
      }
      setIsLoading(false);
      setMemberData(members);
      setNextPage(response.morePages);
      // setMemberAddress(response.address[0]);
    } else {
      setIsLoading(true);
      //console.log(response);
    }
  }

  async function getAllFavouriteMemberList() {
    if (!editor) {
      const response = await getListOfMembersFromId(userFavourites, userToken);
      const favMembers = response;
      for (let index = 0; index < favMembers.length; index++) {
        const member = favMembers[index];
        favMembers[index] = {
          ...member,
          isFavourite: true,
        };
      }
      if (response !== null) {
        setIsLoading(false);
        setMemberData(favMembers);
        // setMemberAddress(response.address[0]);
      } else {
        setIsLoading(true);
        //console.log(response);
      }
    }
  }

  // const addFilter = (filter) => {
  //   const filterList = selectedFilters;
  //   const idx = filterList.indexOf(filter);
  //   if (idx > -1) {
  //     filterList.splice(idx, 1);
  //     setSelectedFilters([...filterList]);
  //   } else {
  //     setSelectedFilters([...filterList, filter]);
  //   }
  // };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const paginate = (step) => {
    goToTop();
    const pageNum = page + step;
    if (pageNum >= 0) {
      setPage(pageNum);
    }
  };

  //Add selectedFilters dependency if adding filters
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchData.length >= 3) {
        getAllMemberList();
      } else {
        getAllFavouriteMemberList();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [page, searchData]);

  const isExpired = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    return date1.getTime() >= date2.getTime();
  };

  const renderMemberProfiles = memberData.map((data, index) => {
    const currentDate = getCurrentDate();
    const expiryDate = data.expiryDate;
    const expired = isExpired(currentDate, expiryDate);
    //console.log("Is expired : ", expired);
    return (
      <Grid item sm={12} md={12} lg={12} xl={6} xxl={6} key={data.id} sx={{ width: "100%" }}>
        <CompanyCard
          isEditor={editor}
          id={data.id}
          image={data.companyLogo}
          isHeadOffice={data.isHeadOffice == "true" && searchData !== ""}
          label={data.address?.map((add) => add.city + ", " + add.country + "  ")}
          description={data.companyProfile}
          title={data.companyName}
          linkedin={data.linkedInUrl}
          action={{
            type: "internal",
            route: `/directory/profile/${data.id}`,
          }}
          authors={data.memberContacts}
          expired={expired}
          isFavourite={data.isFavourite}
          refresh={false}
        />
      </Grid>
    );
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Card sx={{ marginBottom: 3 }}>
        <MDBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
          }}
          component="form"
          role="form"
          onSubmit={handleSubmit}
        >
          <MDBox
            sx={{
              p: 2.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <MDInput
              sx={{ mr: 2, pl: 0, flex: 1 }}
              placeholder="Search by : Company Name | Country | City | User"
              onChange={(e) => setSearchData(e.target.value)}
            />
            <MDButton size="medium" color="primary" type="submit">
              Search
            </MDButton>
          </MDBox>
          {/* {searchData.length < 3 ? null : (
            <MDBox py={2.5} px={2.5} sx={{ borderTop: "1px solid #d7d9e1" }}>
              <MDBox sx={{ display: "flex", flexWrap: "wrap", gap: 1, color: "white" }}>
                {filters.map((item) => (
                  <Chip
                    sx={{
                      border: selectedFilters.includes(item.value)
                        ? "1px solid transparent"
                        : "1px solid white",
                      background: selectedFilters.includes(item.value)
                        ? "#9CE667 !important"
                        : "auto",
                    }}
                    key={item.value}
                    label={
                      <MDTypography
                        variant="button"
                        color={selectedFilters.includes(item.value) ? "secondnary" : "black"}
                      >
                        {item.title}
                      </MDTypography>
                    }
                    onClick={() => addFilter(item.value)}
                  />
                ))}
              </MDBox>
            </MDBox>
          )} */}
        </MDBox>
      </Card>
      {isLoading ? (
        // Render a spinner or loading indicator
        <Stack spacing={3}>
          <MDBox display="flex">
            <Skeleton variant="circular" width={120} height={100} sx={{ mr: 3 }} />
            <Skeleton variant="rectangular" width={"100%"} height={100} />
          </MDBox>
          <MDBox display="flex">
            <Skeleton variant="circular" width={120} height={100} sx={{ mr: 3 }} />
            <Skeleton variant="rectangular" width={"100%"} height={100} />
          </MDBox>
          <Skeleton variant="rectangular" width={"100%"} height={70} />
          <Skeleton variant="rectangular" width={"100%"} height={40} />
          <Skeleton variant="rectangular" width={"100%"} height={20} />
        </Stack>
      ) : (
        <>
          {memberData.length !== 0 ? (
            <Header>
              <Grid
                container
                pt={0.5}
                pb={2}
                px={0}
                lineHeight={1.25}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={12} md={6} xl={7} mb={1} mr={2}>
                  <MDTypography variant="h5" fontWeight="medium" color="black">
                    {searchData.length >= 3 ? "All Companies" : "Favourite Companies"}
                  </MDTypography>
                  {/* <MDBox mb={1}>
              <MDTypography variant='button' color='text'>
                Company Members
              </MDTypography>
            </MDBox> */}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  xl={3.8}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                  sx={{
                    justifyContent: { xs: "start", md: "end" },
                    mt: { xs: 2, md: 0 },
                  }}
                >
                  {/* <MDButton
              component={Link}
              to="mailto:no-reply@example.com"
              size="small"
              color="info"
              variant="outlined"
            >
              Export
            </MDButton> */}
                </Grid>
              </Grid>
              <MDBox py={1}>
                <Grid container spacing={3}>
                  {renderMemberProfiles}
                </Grid>
              </MDBox>
              <MDBox display="flex" justifyContent="end" alignItems="center" mt={3}>
                {page > 0 ? (
                  <MDButton
                    onClick={() => {
                      if (page > 0) {
                        paginate(-1);
                      }
                    }}
                    variant="text"
                    size="small"
                    color="primary"
                  >
                    Previous Page
                  </MDButton>
                ) : null}
                {page > 0 && nextPage ? (
                  <MDTypography variant="button" color="primary">
                    |
                  </MDTypography>
                ) : null}
                {nextPage ? (
                  <MDButton
                    onClick={() => {
                      if (nextPage) {
                        paginate(1);
                      }
                    }}
                    variant="text"
                    size="small"
                    color="primary"
                  >
                    Next Page
                  </MDButton>
                ) : null}
              </MDBox>
            </Header>
          ) : (
            <Grid my={20}>
              <MDTypography
                variant="text"
                color="text"
                display="flex"
                justifyContent="center"
                mb={2}
              >
                No Companies found !
              </MDTypography>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              ></Grid>
            </Grid>
          )}
        </>
      )}

      <Footer />
    </DashboardLayout>
  );
}

export default FavouriteMembers;
