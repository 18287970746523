import * as yup from "yup";
const phoneRules = "[+]{1}[0-9]{9,14}";
// const urlRules = /^(www\.|https?:\/\/)/;
const urlRules = /^(https?:\/\/).*/;
const letterRuls = /^[A-Za-z\s]+$/;
export const userProfileSchema = yup.object().shape({
  displayName: yup
    .string()
    .max(254, "Content is too large")
    .nullable()
    .trim()
    .matches(letterRuls, "Full Name can only include letters")
    .required("Full name is required"),
  title: yup
    .string()
    .max(254, "Content is too large")
    .nullable()
    .trim()
    .required("Title is required"),
  // roles: yup.array().min(1, "User role is required "),
  // email: yup.string().email("Please enter valid Email.").required("Email is required"),
  phoneNumber: yup
    .string()
    .max(254, "Content is too large")
    .nullable()
    .trim()
    .matches(phoneRules, { message: "Please enter valid Phone number" }),
  //   .required("Phone number is required"),
  // unitNo: yup.string().required("House Number is required"),
  // street: yup.string().required("Street is required"),
  // country: yup.string().required("Country is required"),
  // province: yup.string().required("State is required"),
  // city: yup.string().required("City is required"),
  linkedin: yup
    .string()
    .max(254, "Content is too large")
    .nullable()
    .trim()
    .matches(urlRules, "Enter correct url with 'https://'")
    .required("Linkedin URL is required"),
  // conferences: yup.array().min(1, "Select at least one conference"),
  file: yup
    .mixed()
    .nullable()
    .test("fileSize", "Please upload file below 5MB.", (value) => {
      if (value) {
        return value.size <= 5000000;
      }
      return true;
    }),
});
