import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Card } from "@mui/material";
import { useState } from "react";

function CollapsibleCard({
  canExpand,
  initiallyExpanded,
  expanded,
  title,
  children,
  height,
  justifyContent,
  noBackground,
}) {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

  const handleAccChange = () => (event, expanded) => {
    setIsExpanded(isExpanded ? false : true);
  };
  if (noBackground) {
    return (
      <Accordion
        expanded={expanded || isExpanded}
        sx={{ padding: 0, backgroundColor: "rgba(0,0,0,0.0)", boxShadow: 0 }}
        onChange={handleAccChange()}
      >
        {title !== null ? (
          <Card
            sx={{
              mt: 0,
              py: 2,
              px: 3,
              mb: 1,
              justifyContent: justifyContent,
              flexGrow: 1,
            }}
          >
            <AccordionSummary
              style={{ cursor: canExpand ? "pointer" : "default" }}
              expandIcon={canExpand ? <ExpandMore fontSize="large" color="text" /> : null}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ padding: 0 }}
            >
              {title}
            </AccordionSummary>
          </Card>
        ) : null}
        <AccordionDetails sx={{ padding: 0, height: height, overflow: "auto" }}>
          {children}
        </AccordionDetails>
      </Accordion>
    );
  } else {
    return (
      <Card
        sx={{
          mt: 0,
          py: 2,
          px: 3,
          justifyContent: justifyContent,
          flexGrow: 1,
        }}
      >
        <Accordion
          expanded={expanded || isExpanded}
          sx={{ padding: 0, backgroundColor: "rgba(0,0,0,0.0)", boxShadow: 0 }}
          onChange={handleAccChange()}
        >
          {title !== null ? (
            <AccordionSummary
              style={{ cursor: canExpand ? "pointer" : "default" }}
              expandIcon={canExpand ? <ExpandMore fontSize="large" color="text" /> : null}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ padding: 0 }}
            >
              {title}
            </AccordionSummary>
          ) : null}
          <AccordionDetails sx={{ padding: 0, height: height, overflow: "auto" }}>
            {children}
          </AccordionDetails>
        </Accordion>
      </Card>
    );
  }
}

export default CollapsibleCard;
