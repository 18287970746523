var BASE_URL = process.env.REACT_APP_BASE_URL;

var API_BASE_URL = BASE_URL + "marketing-service/banners";

var TENANT_ID = "FW";

export async function addNewBanner(bannerDetails, token) {
  try {
    const response = await fetch(API_BASE_URL, {
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify(bannerDetails),

      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getBannerById(id, token) {
  const response = await fetch(API_BASE_URL + "/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  if (response.ok) {
    return response.json();
  } else {
    return response.status(500).send({ error: "Error loading data" });
  }
}

export async function deleteBannerById(id, token) {
  try {
    const response = await fetch(API_BASE_URL + "/" + id, {
      method: "DELETE",

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function updateBannerById(id, updatedData, token) {
  try {
    const response = await fetch(API_BASE_URL + "/" + id, {
      method: "PUT",

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
      body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getRelevantBanners(token, page, cities, countries, labels, limit) {
  try {
    const response = await fetch(API_BASE_URL + "/published", {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        query: {
          city: cities,
          country: countries,
          labels: labels,
        },
        offset: page,
        limit: limit,
        sortBy: ["priority"],
        sortOrder: "desc",
      }),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function searchAllBanners(token, page, searchText, limit) {
  try {
    const response = await fetch(API_BASE_URL + "/search", {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        query: {
          textSearch: searchText,
        },
        offset: page,
        limit: limit,
        sortBy: ["title"],
        sortOrder: "asc",
      }),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}
