import * as yup from "yup";
const phoneRules = "[+]{1}[0-9]{9,14}";
const urlRules = /^(https?:\/\/).*/;
const letterRuls = /^[A-Za-z\s]+$/;
export const userEditRequestSchema = yup.object().shape({
  displayName: yup
    .string()
    .max(254, "Content is too large")
    .nullable()
    .trim()
    .matches(letterRuls, "Full Name can only include letters")
    .required("Full name is required"),
  title: yup
    .string()
    .max(254, "Content is too large")
    .nullable()
    .trim()
    .required("Title is required"),
  phoneNumber: yup
    .string()
    .max(254, "Content is too large")
    .nullable()
    .trim()
    .matches(phoneRules, { message: "Please enter valid Phone number" }),
  linkedinUrl: yup
    .string()
    .max(254, "Content is too large")
    .nullable()
    .trim()
    .matches(urlRules, "Enter correct url with 'https://'")
    .required("Linkedin URL is required"),
});
