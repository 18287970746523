import secureLocalStorage from "react-secure-storage";
import { ROLE_ACCESS } from "utils/constants/roles.constants";
import PotentialPartnersMemberView from "./potentialPartnersMemberView";
import PotentialPartnersAdminView from "./potentialPartnersAdminView";

function PotentialPartners() {
  const editor = JSON.parse(secureLocalStorage.getItem("current-user-data"))[
    ROLE_ACCESS.EDIT_ACCESS
  ];

  if (editor) {
    return <PotentialPartnersAdminView />;
  } else {
    return <PotentialPartnersMemberView />;
  }
}

export default PotentialPartners;
