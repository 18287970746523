import { GENERAL_CONSTS } from "../utils/constants/general.constants";

var API_BASE_URL = process.env.REACT_APP_CMS_BASE_URL;
var token = process.env.REACT_APP_CMS_ANNOUNCEMENTS_TOKEN;
var conRegToken = process.env.REACT_APP_CMS_CON_REGISTER_TOKEN;
var APP_BASE_URL = process.env.REACT_APP_BASE_URL;
var BASE_URL = process.env.REACT_APP_BASE_URL;
var DELETE_FILE_URL = BASE_URL + "content-repo-service/content-repo/delete-file";

export async function getAllAnnouncements(limit) {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/announcements/not-expired${limit !== null ? `?limit=${limit}` : ""}`,
      {
        method: "GET",
        // Adding headers to the request
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: token,
        },
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function newAnnouncement(content) {
  try {
    const response = await fetch(API_BASE_URL + "/api/announcements", {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify(content),

      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function updateAnnouncementById(id, updatedData) {
  //console.log("Updated announcement data: ", JSON.stringify(updatedData));
  try {
    const response = await fetch(API_BASE_URL + "/api/announcements/" + id, {
      method: "PUT",

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
      body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getAnnouncementById(id) {
  const response = await fetch(API_BASE_URL + "/api/announcements/" + id, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: token,
    },
  });
  //console.log("Fetching details :", response);
  if (response.ok) {
    return response.json();
  } else {
    return response.status(500).send({ error: "Error loading list" });
  }
}

export async function removeAnnouncement(id) {
  try {
    const response = await fetch(API_BASE_URL + "/api/announcements/" + id, {
      method: "DELETE",

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getAllConRegistrations(data, filter, page) {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/conference-registrations?filters[company_name][$containsi]=${data}&populate=address&populate=attendees&pagination[page]=${page}&pagination[pageSize]=100`,
      {
        method: "GET",
        // Adding headers to the request
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: conRegToken,
        },
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getConRegistrationStatics(token) {
  try {
    const response = await fetch(`${APP_BASE_URL}audit-service/conference/statistics`, {
      method: "GET",
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function addConfAttendee(attendeeDetails) {
  //console.log("new member data in service : ", JSON.stringify(attendeeDetails));
  try {
    const response = await fetch(`${API_BASE_URL}/api/conference-registrations`, {
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify(attendeeDetails),

      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getConfRegId(id) {
  //console.log("id in serivice", id);
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/conference-registrations?filters[attendees][user_id][$contains]=${id}`,
      {
        method: "GET",
        // Adding headers to the request
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: conRegToken,
        },
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading id" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function removeConfRegAttendee(id) {
  //console.log("delete reg id in serivice", id);
  try {
    const response = await fetch(API_BASE_URL + "/api/conference-registrations/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: conRegToken,
      },
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function deleteDocument(userToken, folderName, fileName) {
  const formdata = new FormData();
  formdata.append("appId", "FreytWorld");
  formdata.append("fileName", fileName);
  formdata.append("folder", GENERAL_CONSTS.DOCUMENT_UPLOAD_ROOT_FOLDER + folderName);
  try {
    const response = await fetch(DELETE_FILE_URL, {
      method: "DELETE",

      // Adding body or contents to send
      body: formdata,

      // Adding headers to the request
      headers: {
        Authorization: userToken,
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}
