import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import Header from "layouts/profile/components/Header";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import MDInput from "components/MDInput";
import { width } from "@mui/system";
import { Link, useNavigate, useParams } from "react-router-dom";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import LinkIcon from "@mui/icons-material/Link";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import { userProfileSchema } from "../../../validationSchema/userProfileSchema";
import { getUserById } from "api_services/UserService";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { UserAuth } from "context/AuthContext";
import { updateUserByID } from "api_services/UserService";
import MDAvatar from "components/MDAvatar";
import burceMars from "assets/images/bruce-mars.jpg";
import { GENERAL_CONSTS } from "utils/constants/general.constants";
import { getCustomToken } from "api_services/UserService";
import { getCurrentUser } from "api_services/UserService";
import { getUsersCompanies } from "api_services/MemberService";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { getUserLastLoginById } from "api_services/UserService";
import { getUserLoginCountById } from "api_services/UserService";
import secureLocalStorage from "react-secure-storage";
import { ROLE_ACCESS } from "utils/constants/roles.constants";
import { getUserUpdateRequestById } from "api_services/UserService";
import { userEditRequestSchema } from "layouts/validationSchema/userEditRequestSchema";
import { BorderColor, Clear, Replay } from "@mui/icons-material";
import { updateUserUpdateRequestStatus } from "api_services/UserService";

function UserProfileEditRequest() {
  const { id } = useParams();
  const [userData, setUserData] = useState([]);
  const [userEditRequest, setUserEditRequest] = useState([]);
  const userToken = secureLocalStorage.getItem("custom-token");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editField, setEditField] = useState({
    displayName: false,
    title: false,
    phoneNumber: false,
    linkedinUrl: false,
  });
  const navigate = useNavigate();
  async function getUserByID() {
    const response = await getUserById(id, userToken);

    if (response !== null) {
      setIsLoading(false);
      setUserData(response);
    } else {
      setIsLoading(true);
      //console.log(response);
    }
  }

  async function getUserUpdateRequest() {
    const response = await getUserUpdateRequestById(userToken, id);

    if (response !== null) {
      setIsLoading(false);
      setUserEditRequest(response);
      //console.log(response);
      //console.log("All Requests", response.searchResults);
    } else {
      setIsLoading(true);
      //console.log(response);
    }
  }

  useEffect(() => {
    getUserByID();
    getUserUpdateRequest();
  }, []);

  const [positionState, setPositionState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = positionState;
  const handleAlertClose = () => {
    setPositionState({ ...positionState, open: false });
  };

  async function updateUser(updatedData) {
    setLoadingOpen(true);
    setSaving(true);
    const response_db = await updateUserByID(id, userToken, updatedData);
    const response = await updateUserUpdateRequestStatus(userToken, id, "false");
    if (response_db == "ok") {
      //console.log("updated DB Successfully");
      navigate(-1);
    } else {
      setLoadingOpen(false);
      setSaving(false);
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
      //console.log("update failed");
    }
  }

  const onSubmit = async () => {
    try {
      const userObj = {
        ...userData,
        displayName: values.displayName,
        title: values.title,
        phoneNumber: values.phoneNumber,
        avatar: values.avatar,
        linkedinUrl: values.linkedinUrl,
        hasWhatsapp: userEditRequest.phoneNumber.isUpdated ? false : userData.hasWhatsapp,
      };

      // console.log("User object: ", userObj);
      updateUser(userObj);
      ////console.log("user address object //////////////////// ", userAddressObj);

      // updateUserAddress(userAddressObj);
      // setPositionState({ open: true, vertical: "bottom", horizontal: "right" });
      // setExpanded(false);
    } catch (e) {
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      avatar: userEditRequest.avatar?.newValue,
      displayName: userEditRequest.displayName?.newValue,
      title: userEditRequest.title?.newValue,
      phoneNumber: userEditRequest.phoneNumber?.newValue,
      linkedinUrl: userEditRequest.linkedinUrl?.newValue,
    },
    validationSchema: userEditRequestSchema,
    onSubmit,
  });

  const editProfile = (
    <Grid
      container
      pt={2}
      pl={0.5}
      pr={1}
      mb={5}
      lineHeight={1.25}
      display="flex"
      alignItems="start"
      justifyContent="space-between"
    >
      <Grid
        item
        xs={12}
        md={6}
        xl={5}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <MDAvatar
          src={!userData.avatar ? "./placeholder.jpg" : userData.avatar}
          alt="profile-image"
          size="xl"
          shadow="sm"
          variant="square"
          sx={{ borderRadius: 3 }}
        />
        <MDBox ml={5}>
          <MDTypography variant="h5" fontWeight="medium" color="black">
            {userData.displayName}
          </MDTypography>
          <MDTypography variant="button" color="black">
            {userData.email}
          </MDTypography>
        </MDBox>
      </Grid>
    </Grid>
  );

  return (
    <>
      <style>
        {`.css-kycn4l-MuiPaper-root-MuiAccordion-root::before { height: 0px; background-color : white } 
          .css-a5rdam-MuiGrid-root > .MuiGrid-item {padding-top: 0px;}`}
      </style>

      <DashboardLayout>
        <DashboardNavbar />
        <MDBox />
        <Header>
          {isLoading ? (
            // Render a spinner or loading indicator
            <Stack spacing={3}>
              {/* For variant="text", adjust the height via font-size */}
              <Skeleton variant="text" sx={{ fontSize: "0.8rem" }} />
              {/* For other variants, adjust the size with `width` and `height` */}
              <MDBox display="flex">
                <Skeleton variant="circular" width={160} height={140} sx={{ mr: 5 }} />
                <Skeleton variant="rectangular" width={"100%"} height={140} />
              </MDBox>

              <Skeleton variant="rectangular" width={"100%"} height={20} />
              <Skeleton variant="rounded" width={"100%"} height={200} />
              <Skeleton variant="rectangular" width={"100%"} height={20} />
            </Stack>
          ) : (
            <>
              {editProfile}

              <MDBox mx={2} pb={3}>
                <Grid container spacing={1}>
                  {/* <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} /> */}
                  <Grid container spacing={1} component="form" role="form" onSubmit={handleSubmit}>
                    <Grid item xs={12} md={12} xl={12} alignItems="center" lineHeight={1}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        textTransform="capitalize"
                        color="black"
                      >
                        Profile Changes
                      </MDTypography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} xl={12}>
                      {userEditRequest.avatar?.isUpdated ? (
                        <MDBox display="flex" mt={2} alignItems="center">
                          <MDAvatar
                            src={
                              !userEditRequest.avatar.oldValue
                                ? "./placeholder.jpg"
                                : userEditRequest.avatar.oldValue
                            }
                            name={userData.displayName}
                            size="xxl"
                            variant="square"
                            sx={{ borderRadius: 3, border: "3px solid red", opacity: "0.5" }}
                          />
                          <MDBox px={0.3} />
                          <MDAvatar
                            src={
                              !userEditRequest.avatar.newValue
                                ? "./placeholder.jpg"
                                : userEditRequest.avatar.newValue
                            }
                            name={userData.displayName}
                            size="xxl"
                            variant="square"
                            sx={{ borderRadius: 3, border: "3px solid #A1FFAE" }}
                          />
                          <MDTypography
                            ml={2}
                            variant="button"
                            color="black"
                            fontWeight="small"
                            sx={{ cursor: "pointer", opacity: 0.7 }}
                            title="Revert change"
                            onClick={() => {
                              setFieldValue("avatar", userData.avatar);
                              setUserEditRequest({
                                ...userEditRequest,
                                avatar: {
                                  ...userEditRequest.avatar,
                                  isUpdated: false,
                                },
                              });
                            }}
                          >
                            <Replay fontSize="medium" />
                          </MDTypography>
                        </MDBox>
                      ) : null}
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} xl={6} mt={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        textTransform="capitalize"
                        color="black"
                      >
                        Full Name
                      </MDTypography>
                      <MDBox lineHeight={0}>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                          color="black"
                        >
                          {userEditRequest.displayName?.isUpdated ? (
                            <MDBox display="flex" flexDirection="column">
                              <MDBox display="flex" alignItems="center">
                                {!editField.displayName ? (
                                  <MDTypography
                                    py={0.2}
                                    px={0.5}
                                    mb={0.5}
                                    sx={{ backgroundColor: "#DAFBE1", width: "fit-content" }}
                                    variant="button"
                                    fontWeight="small"
                                    color="black"
                                  >
                                    {userEditRequest.displayName.newValue}
                                  </MDTypography>
                                ) : (
                                  <MDInput
                                    sx={{ margin: "10px 0px 10px 0px", width: "60%" }}
                                    error={errors.displayName && touched.displayName && true}
                                    focused={
                                      (errors.displayName && touched.displayName && true) ||
                                      (values.displayName && true)
                                    }
                                    label={
                                      errors.displayName && touched.displayName
                                        ? errors.displayName
                                        : "Full Name"
                                    }
                                    type="text"
                                    id="displayName"
                                    onChange={handleChange}
                                    value={values.displayName}
                                    onBlur={handleBlur}
                                  />
                                )}
                                <MDTypography
                                  ml={2.5}
                                  variant="button"
                                  color="black"
                                  fontWeight="small"
                                  sx={{ cursor: "pointer", opacity: 0.7 }}
                                  title="Revert change"
                                  onClick={() => {
                                    setFieldValue("displayName", userData.displayName);
                                    setEditField({
                                      ...editField,
                                      displayName: false,
                                    });
                                    setUserEditRequest({
                                      ...userEditRequest,
                                      displayName: {
                                        ...userEditRequest.displayName,
                                        isUpdated: false,
                                      },
                                    });
                                  }}
                                >
                                  <Replay fontSize="small" />
                                </MDTypography>
                                <MDBox px={0.5} />
                                <MDTypography
                                  variant="button"
                                  color="black"
                                  fontWeight="small"
                                  sx={{ cursor: "pointer", opacity: 0.7 }}
                                  title={
                                    !editField.displayName ? "Edit change" : "Cancel editing change"
                                  }
                                  onClick={() => {
                                    if (editField.displayName) {
                                      setFieldValue(
                                        "displayName",
                                        userEditRequest.displayName.newValue
                                      );
                                    }
                                    setEditField({
                                      ...editField,
                                      displayName: !editField.displayName,
                                    });
                                  }}
                                >
                                  {!editField.displayName ? (
                                    <BorderColor fontSize="small" />
                                  ) : (
                                    <Clear fontSize="medium" />
                                  )}
                                </MDTypography>
                              </MDBox>
                              <MDTypography
                                p={0.2}
                                px={0.5}
                                sx={{ backgroundColor: "#FFEBE9", width: "fit-content" }}
                                variant="button"
                                fontWeight="small"
                                color="black"
                              >
                                {userEditRequest.displayName.oldValue}
                              </MDTypography>
                            </MDBox>
                          ) : (
                            <MDTypography variant="button" fontWeight="small" color="black">
                              {userData.displayName}
                            </MDTypography>
                          )}
                        </MDTypography>
                      </MDBox>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} xl={6} mt={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        textTransform="capitalize"
                        color="black"
                      >
                        Title
                      </MDTypography>
                      <MDBox lineHeight={0}>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                          color="black"
                        >
                          {userEditRequest.title?.isUpdated ? (
                            <MDBox display="flex" flexDirection="column">
                              <MDBox display="flex" alignItems="center">
                                {!editField.title ? (
                                  <MDTypography
                                    py={0.2}
                                    px={0.5}
                                    mb={0.5}
                                    sx={{ backgroundColor: "#DAFBE1", width: "fit-content" }}
                                    variant="button"
                                    fontWeight="small"
                                    color="black"
                                  >
                                    {userEditRequest.title.newValue}
                                  </MDTypography>
                                ) : (
                                  <MDInput
                                    sx={{ margin: "10px 0px 10px 0px", width: "60%" }}
                                    error={errors.title && touched.title && true}
                                    focused={
                                      (errors.title && touched.title && true) ||
                                      (values.title && true)
                                    }
                                    label={errors.title && touched.title ? errors.title : "Title"}
                                    type="text"
                                    id="title"
                                    onChange={handleChange}
                                    value={values.title}
                                    onBlur={handleBlur}
                                  />
                                )}
                                <MDTypography
                                  ml={2.5}
                                  variant="button"
                                  color="black"
                                  fontWeight="small"
                                  sx={{ cursor: "pointer", opacity: 0.7 }}
                                  title="Revert change"
                                  onClick={() => {
                                    setFieldValue("title", userData.title);
                                    setUserEditRequest({
                                      ...userEditRequest,
                                      title: {
                                        ...userEditRequest.title,
                                        isUpdated: false,
                                      },
                                    });
                                  }}
                                >
                                  <Replay fontSize="small" />
                                </MDTypography>
                                <MDBox px={0.5} />

                                <MDTypography
                                  variant="button"
                                  color="black"
                                  fontWeight="small"
                                  sx={{ cursor: "pointer", opacity: 0.7 }}
                                  title={!editField.title ? "Edit change" : "Cancel editing change"}
                                  onClick={() => {
                                    if (editField.title) {
                                      setFieldValue("title", userEditRequest.title.newValue);
                                    }
                                    setEditField({
                                      ...editField,
                                      title: !editField.title,
                                    });
                                  }}
                                >
                                  {!editField.title ? (
                                    <BorderColor fontSize="small" />
                                  ) : (
                                    <Clear fontSize="medium" />
                                  )}
                                </MDTypography>
                              </MDBox>

                              <MDTypography
                                p={0.2}
                                px={0.5}
                                sx={{ backgroundColor: "#FFEBE9", width: "fit-content" }}
                                variant="button"
                                fontWeight="small"
                                color="black"
                              >
                                {userEditRequest.title.oldValue}
                              </MDTypography>
                            </MDBox>
                          ) : (
                            <MDTypography variant="button" fontWeight="small" color="black">
                              {userData.title}
                            </MDTypography>
                          )}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={6} mt={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        mb={2}
                        textTransform="capitalize"
                        color="black"
                      >
                        Contact Number
                      </MDTypography>
                      <MDBox lineHeight={0}>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          mb={2}
                          textTransform="capitalize"
                          color="black"
                        >
                          {userEditRequest.phoneNumber?.isUpdated ? (
                            <MDBox display="flex" flexDirection="column">
                              <MDBox display="flex" alignItems="center">
                                {!editField.phoneNumber ? (
                                  <MDTypography
                                    py={0.2}
                                    px={0.5}
                                    mb={0.5}
                                    sx={{ backgroundColor: "#DAFBE1", width: "fit-content" }}
                                    variant="button"
                                    fontWeight="small"
                                    color="black"
                                  >
                                    {userEditRequest.phoneNumber.newValue}
                                  </MDTypography>
                                ) : (
                                  <MDInput
                                    sx={{ margin: "10px 0px 10px 0px", width: "60%" }}
                                    error={errors.phoneNumber && touched.phoneNumber && true}
                                    focused={
                                      (errors.phoneNumber && touched.phoneNumber && true) ||
                                      (values.phoneNumber && true)
                                    }
                                    label={
                                      errors.phoneNumber && touched.phoneNumber
                                        ? errors.phoneNumber
                                        : "Phone Number"
                                    }
                                    type="text"
                                    id="phoneNumber"
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    onBlur={handleBlur}
                                  />
                                )}
                                <MDTypography
                                  ml={2.5}
                                  variant="button"
                                  color="black"
                                  fontWeight="small"
                                  sx={{ cursor: "pointer", opacity: 0.7 }}
                                  title="Revert change"
                                  onClick={() => {
                                    setFieldValue("phoneNumber", userData.phoneNumber);
                                    setUserEditRequest({
                                      ...userEditRequest,
                                      phoneNumber: {
                                        ...userEditRequest.phoneNumber,
                                        isUpdated: false,
                                      },
                                    });
                                  }}
                                >
                                  <Replay fontSize="small" />
                                </MDTypography>
                                <MDBox px={0.5} />
                                <MDTypography
                                  variant="button"
                                  color="black"
                                  fontWeight="small"
                                  sx={{ cursor: "pointer", opacity: 0.7 }}
                                  title={
                                    !editField.phoneNumber ? "Edit change" : "Cancel editing change"
                                  }
                                  onClick={() => {
                                    if (editField.phoneNumber) {
                                      setFieldValue(
                                        "phoneNumber",
                                        userEditRequest.phoneNumber.newValue
                                      );
                                    }
                                    setEditField({
                                      ...editField,
                                      phoneNumber: !editField.phoneNumber,
                                    });
                                  }}
                                >
                                  {!editField.phoneNumber ? (
                                    <BorderColor fontSize="small" />
                                  ) : (
                                    <Clear fontSize="medium" />
                                  )}
                                </MDTypography>
                              </MDBox>
                              <MDTypography
                                p={0.2}
                                px={0.5}
                                sx={{ backgroundColor: "#FFEBE9", width: "fit-content" }}
                                variant="button"
                                fontWeight="small"
                                color="black"
                              >
                                {userEditRequest.phoneNumber.oldValue}
                              </MDTypography>
                            </MDBox>
                          ) : (
                            <MDTypography variant="button" fontWeight="small" color="black">
                              {userData.phoneNumber}
                            </MDTypography>
                          )}
                        </MDTypography>
                      </MDBox>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} xl={6} mt={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        mb={2}
                        textTransform="capitalize"
                        color="black"
                      >
                        LinkedIn
                      </MDTypography>
                      <MDBox lineHeight={0}>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          mb={2}
                          textTransform="capitalize"
                          color="black"
                        >
                          {userEditRequest.linkedinUrl?.isUpdated ? (
                            <MDBox display="flex" flexDirection="column">
                              <MDBox display="flex" alignItems="center">
                                {!editField.linkedinUrl ? (
                                  <MDTypography
                                    py={0.2}
                                    px={0.5}
                                    mb={0.5}
                                    sx={{ backgroundColor: "#DAFBE1", width: "fit-content" }}
                                    variant="button"
                                    fontWeight="small"
                                    color="black"
                                  >
                                    {userEditRequest.linkedinUrl.newValue}
                                  </MDTypography>
                                ) : (
                                  <MDInput
                                    sx={{ margin: "10px 0px 10px 0px", width: "60%" }}
                                    error={errors.linkedinUrl && touched.linkedinUrl && true}
                                    focused={
                                      (errors.linkedinUrl && touched.linkedinUrl && true) ||
                                      (values.linkedinUrl && true)
                                    }
                                    label={
                                      errors.linkedinUrl && touched.linkedinUrl
                                        ? errors.linkedinUrl
                                        : "LinkedIn URL"
                                    }
                                    type="text"
                                    id="linkedinUrl"
                                    onChange={handleChange}
                                    value={values.linkedinUrl}
                                    onBlur={handleBlur}
                                  />
                                )}
                                <MDTypography
                                  ml={2.5}
                                  variant="button"
                                  color="black"
                                  fontWeight="small"
                                  sx={{ cursor: "pointer", opacity: 0.7 }}
                                  title="Revert change"
                                  onClick={() => {
                                    setFieldValue("linkedinUrl", userData.linkedinUrl);
                                    setUserEditRequest({
                                      ...userEditRequest,
                                      linkedinUrl: {
                                        ...userEditRequest.linkedinUrl,
                                        isUpdated: false,
                                      },
                                    });
                                  }}
                                >
                                  <Replay fontSize="small" />
                                </MDTypography>
                                <MDBox px={0.5} />
                                <MDTypography
                                  variant="button"
                                  color="black"
                                  fontWeight="small"
                                  sx={{ cursor: "pointer", opacity: 0.7 }}
                                  title={
                                    !editField.linkedinUrl ? "Edit change" : "Cancel editing change"
                                  }
                                  onClick={() => {
                                    if (editField.linkedinUrl) {
                                      setFieldValue(
                                        "linkedinUrl",
                                        userEditRequest.linkedinUrl.newValue
                                      );
                                    }
                                    setEditField({
                                      ...editField,
                                      linkedinUrl: !editField.linkedinUrl,
                                    });
                                  }}
                                >
                                  {!editField.linkedinUrl ? (
                                    <BorderColor fontSize="small" />
                                  ) : (
                                    <Clear fontSize="medium" />
                                  )}
                                </MDTypography>
                              </MDBox>

                              <MDTypography
                                p={0.2}
                                px={0.5}
                                sx={{ backgroundColor: "#FFEBE9", width: "fit-content" }}
                                variant="button"
                                fontWeight="small"
                                color="black"
                              >
                                {userEditRequest.linkedinUrl.oldValue}
                              </MDTypography>
                            </MDBox>
                          ) : (
                            <MDTypography variant="button" fontWeight="small" color="black">
                              {userData.linkedinUrl}
                            </MDTypography>
                          )}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      xl={12}
                      pt={5}
                      mt={5}
                      px={2}
                      display="flex"
                      justifyContent="end"
                      alignItems="end"
                    >
                      <MDButton
                        size="medium"
                        variant="gradient"
                        color="primary"
                        sx={{ px: 8 }}
                        type="submit"
                      >
                        Save Changes
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </>
          )}
        </Header>
        <Footer />

        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleAlertClose} severity="success" sx={{ width: "100%" }}>
            Error updating user profile
          </Alert>
        </Snackbar>

        <MDBox sx={{ overflowY: "visible" }}>
          <Dialog
            open={loadingOpen}
            // onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            sx={{ overflowY: "visible" }}
          >
            <MDBox mb={1} mt={2} sx={{ mx: { xs: 5, md: 10 } }} textAlign="center">
              <MDTypography display="block" variant="button" color="primaryDark">
                Saving Details ...
              </MDTypography>
              {saving && <CircularProgress sx={{ mt: 2 }} />}
            </MDBox>
          </Dialog>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default UserProfileEditRequest;
