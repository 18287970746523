// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { Grid, Icon } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import { convert } from "html-to-text";
import FavouriteIcon from "common/Favourite";
const options = {
  wordwrap: 130,
};

function CompanyCard({
  id,
  image,
  label,
  description,
  title,
  linkedin,
  action,
  authors,
  expired,
  isHeadOffice,
  isEditor,
  isFavourite,
  refresh,
}) {
  const renderAuthors = authors.map(({ avatar, displayName }) => (
    <Tooltip key={displayName} title={displayName} placement="bottom">
      <MDAvatar
        src={!avatar ? "./placeholder.jpg" : avatar}
        alt={displayName}
        size="xs"
        variant="square"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",
          ml: -1.25,
          borderRadius: 3,

          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    </Tooltip>
  ));

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
        boxShadow: ({ boxShadows: { md } }) => md,
        px: 2,
        py: 2,
        height: "100%",
        width: "100%",
      }}
    >
      <Grid
        container
        spacing={1}
        display="flex"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Grid
          item
          xs={3}
          sm={3}
          md={1.5}
          lg={3}
          xl={3}
          position="relative"
          borderRadius="xl"
          component={Link}
          to={action.route}
        >
          <CardMedia
            src={!image ? "./placeholder.jpg" : image}
            component="img"
            title="logo"
            mr={2}
            sx={{
              width: "96px",
              height: "96px",
              mb: { xs: 2, sm: 2, md: 0 },
              margin: 0,
              objectFit: "contain",
              objectPosition: "center",
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          xl={7}
          pt={0}
          pl={0}
          display="flex"
          alignItems="start"
          component={Link}
          to={action.route}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div lineHeight={0}>
              <MDBox sx={{ mt: { xs: -1, sm: -1, md: -1, lg: -1, xl: -1 } }}>
                {action.type === "internal" ? (
                  <MDTypography
                    component={Link}
                    to={action.route}
                    variant="h6"
                    textTransform="capitalize"
                    color="black"
                  >
                    {title}
                  </MDTypography>
                ) : (
                  <MDTypography
                    component="a"
                    href={action.route}
                    target="_blank"
                    rel="noreferrer"
                    variant="h6"
                    textTransform="capitalize"
                    color="black"
                  >
                    {title}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mt={0} lineHeight={0}>
                <MDTypography variant="button" fontWeight="bold" color="primary">
                  {label}
                </MDTypography>
              </MDBox>
              <MDBox mt={0.5} lineHeight={0}>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  color="black"
                  dangerouslySetInnerHTML={{
                    __html: convert(description, options).slice(0, 100) + "...",
                  }}
                ></MDTypography>
              </MDBox>
              <MDBox display="flex" ml={1} mt={1}>
                {renderAuthors}
              </MDBox>
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={1}
          xl={1}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{ flexDirection: { md: "column", sm: "row" } }}
        >
          <MDBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{ justifyContent: { md: "end", sm: "start" } }}
          >
            {/* {action.type === "internal" ? (
              <MDButton
                component={Link}
                to={action.route}
                variant="outlined"
                size="small"
                color={action.color}
              >
                {action.label}
              </MDButton>
            ) : (
              <MDButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                size="small"
                color={action.color}
              >
                {action.label}
              </MDButton>
            )} */}

            {expired ? (
              <MDTypography variant="caption" fontWeight="regular" color="error" mr={1}>
                Expired
              </MDTypography>
            ) : null}
            {/* {isHeadOffice ? (
              <MDTypography
                title="Head Office"
                variant="button"
                fontWeight="bold"
                color="success"
                mr={1}
                style={{ cursor: "help" }}
              >
                HQ
              </MDTypography>
            ) : null} */}
            <MDBox
              display="flex"
              component="a"
              href={linkedin}
              target="_blank"
              variant="button"
              fontWeight="light"
              color="text"
            >
              <LinkedInIcon fontSize="medium" color="info" />
            </MDBox>
          </MDBox>
          {!isEditor ? (
            <MDBox sx={{ width: "36px" }}>
              <FavouriteIcon isFavourite={isFavourite} memberId={id} refresh={refresh} />
            </MDBox>
          ) : null}
        </Grid>
      </Grid>
    </Card>
  );
}

// Setting default values for the props of DefaultProjectCard
CompanyCard.defaultProps = {
  authors: [],
};

// Typechecking props for the DefaultProjectCard
CompanyCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
};

export default CompanyCard;
