import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, HashRouter } from "react-router-dom";
import App from "App";
import "./i18next";
// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { AuthContextProvider } from "context/AuthContext";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

function Media(props) {
  const { loading = false } = props;

  return (
    <Grid container wrap="nowrap">
      {loading ? Array.from(new Array(3)) : null}
    </Grid>
  );
}

Media.propTypes = {
  loading: PropTypes.bool,
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <HashRouter>
    <AuthContextProvider>
      <MaterialUIControllerProvider>
        <Suspense
          fallback={
            <Box sx={{ overflow: "hidden" }}>
              <Media loading />
            </Box>
          }
        >
          <App />
        </Suspense>
      </MaterialUIControllerProvider>
    </AuthContextProvider>
  </HashRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
