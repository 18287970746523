import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import SidenavCollapse from "./SidenavCollapse";

function SidenavSubmenu({ name, key, textColor, nestedItems }) {
  const [openMenu, setOpenMenu] = useState(false);
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const navigate = useNavigate();

  const toggleMenuOpen = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <MDBox display="flex" flexDirection="column">
      <MDBox
        onClick={toggleMenuOpen}
        display="flex"
        flexDirection="row"
        alignItems="center"
        mb={1}
        mt={2}
        sx={{ cursor: "pointer", marginLeft: "34px" }}
      >
        <img
          src="/icons/arrow.png"
          alt="Drop down menu"
          width={9}
          height={9}
          style={{ rotate: openMenu ? "90deg" : "0deg", transition: "rotate 0.4s" }}
        />
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          ml={1}
        >
          {name}
        </MDTypography>
      </MDBox>
      {openMenu
        ? nestedItems.map(({ type, name, icon, title, noCollapse, key, href, route }) => (
            <NavLink key={key} to={route}>
              <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
            </NavLink>
          ))
        : null}
    </MDBox>
  );
}

export default SidenavSubmenu;
