// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/system";

function Footer({ light }) {
  const { t } = useTranslation();
  const { size } = typography;
  const currentYear = new Date().getFullYear();

  // const renderLinks = () =>
  //   links.map((link) => (
  //     <MDBox key={link.name} component="li" px={2} lineHeight={1}>
  //       <Link href={link.href} target="_blank">
  //         <MDTypography variant="button" fontWeight="regular" color="text">
  //           {link.name}
  //         </MDTypography>
  //       </Link>
  //     </MDBox>
  //   ));

  return (
    <MDBox width="100%" bottom={0} py={4}>
      <Container>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="space-between"
          alignItems="center"
          px={1}
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color={light ? "white" : "text"}
            fontSize={size.sm}
          >
            &copy; {currentYear}, Freyt World Pte Ltd
          </MDBox>
          <MDBox
            component="ul"
            sx={({ breakpoints }) => ({
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              listStyle: "none",
              mt: 3,
              mb: 0,
              p: 0,

              [breakpoints.up("lg")]: {
                mt: 0,
              },
            })}
          >
            <MDBox component="li" px={2} lineHeight={1}>
              <Link href="https://freytworld.com/about-us" target="_blank">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color={light ? "white" : "text"}
                >
                  {t("footer.aboutus")}
                </MDTypography>
              </Link>
            </MDBox>
            <MDBox component="li" px={2} lineHeight={1}>
              <Link href="https://freytworld.com/contact-us" target="_blank">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color={light ? "white" : "text"}
                >
                  {t("footer.contact")}
                </MDTypography>
              </Link>
            </MDBox>
            {/* <MDBox component="li" pl={2} lineHeight={1}>
              <Link href="" target="_blank">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color={light ? "white" : "text"}
                  pr={3}
                >
                  {t("footer.license")}
                </MDTypography>
              </Link>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}

// // Setting default values for the props of Footer
// // Footer.defaultProps = {
// //   links: [
// //     { href: "", name: t('footer.aboutus') },
// //     { href: "", name: t('footer.blog') },
// //     { href: "", name: t('footer.license') },
// //   ],
// // };

// // Typechecking props for the Footer
// Footer.propTypes = {
//   company: PropTypes.objectOf(PropTypes.string),
//   links: PropTypes.arrayOf(PropTypes.object),
// };

export default Footer;
