import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function CarouselCard({ url, image }) {
  return (
    <MDBox display="flex" flexDirection="column" alignItems="center">
      <MDBox
        sx={{
          width: "min(100%, 941.6px)",
          height: "208.8px",
        }}
        href={url}
      >
        {url !== null && url.length > 0 ? (
          <a href={url} target="_blank">
            <img
              alt=""
              width="100%"
              height="min(auto, 196px)"
              src={image}
              style={{ borderRadius: "17px", objectFit: "cover" }}
            />
          </a>
        ) : (
          <img
            alt=""
            width="100%"
            height="min(auto, 196px)"
            src={image}
            style={{ borderRadius: "17px", objectFit: "cover" }}
          />
        )}
      </MDBox>
    </MDBox>
  );
}

export default CarouselCard;
