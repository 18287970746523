import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  updatePassword,
} from "firebase/auth";
import { auth } from "../firebase";
import { getCustomToken } from "api_services/UserService";
import { getCurrentUser } from "api_services/UserService";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { signInUser } from "../api_services/UserService";

const UserContext = createContext();

export async function getCusToken(userToken) {
  const response = await getCustomToken(userToken);

  if (response !== null) {
    //console.log("Custom Token : ", response.token);
    secureLocalStorage.setItem("custom-token", response.token);
  } else {
    //console.log("null response", response);
  }
}

export async function getCurUser() {
  const userToken = secureLocalStorage.getItem("custom-token");
  if (!secureLocalStorage.getItem("current-user-data")) {
    const response = await getCurrentUser(userToken, true);
    //console.log("Response", response.id !== null);
    if (response.id !== null && response.id !== undefined) {
      secureLocalStorage.setItem("current-user-data", JSON.stringify(response));
      secureLocalStorage.setItem("linked-user-companies", JSON.stringify(response.companies));
      secureLocalStorage.setItem("user-favourites", JSON.stringify(response.favouriteCompanies));
      secureLocalStorage.setItem("current-user-address", JSON.stringify(response.address[0]));
      const userData = secureLocalStorage.getItem("current-user-data");
      ////console.log("User Data : ", userData);
    } else {
      //console.log(response);
    }
  }
}

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const createContact = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const setUserDetails = async (fireBaseUserObj) => {
    //console.log("firbase-current-user", fireBaseUserObj);
    setCurrentUser(fireBaseUserObj);
    const userToken = fireBaseUserObj.idToken;
    const refreshToken = fireBaseUserObj.refreshToken;
    secureLocalStorage.setItem("access-token", userToken);
    secureLocalStorage.setItem("refresh-token", refreshToken);
    await getCusToken(userToken);
    await getCurUser(userToken);
  };

  const signIn = async (email, password) => {
    // const userObj = await signInUser(email, password);
    // await setUserDetails(userObj);

    //Login via firebase library
    return signInWithEmailAndPassword(auth, email, password);
  };

  // const persistence = (rememberMe) => {
  //   return setPersistence(rememberMe ? auth.Auth.Persistence.LOCAL : auth.Auth.Persistence.SESSION);
  // };

  const logOut = () => {
    return signOut(auth)
      .then((u) => {
        secureLocalStorage.clear();
      })
      .catch((error) => {
        ////console.log("Error");
      });
  };

  const resetPw = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  const [user, setCurrentUser] = useState({});

  // Login via firebase library
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      //console.log("firbase-current-user", currentUser);
      setCurrentUser(currentUser);
      const userToken = currentUser.accessToken;
      const refreshToken = currentUser.refreshToken;
      secureLocalStorage.setItem("access-token", userToken);
      secureLocalStorage.setItem("refresh-token", refreshToken);
      await getCusToken(userToken);
      await getCurUser(userToken);
      // navigate("/dashboard");
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const updatePw = (password) => {
    return updatePassword(password);
  };

  return (
    <UserContext.Provider value={{ createContact, user, logOut, signIn, resetPw, updatePw }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
