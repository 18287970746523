import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "common/Breadcrumbs";
import NotificationItem from "common/Items/NotificationItem";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import ApartmentIcon from "@mui/icons-material/Apartment";

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "common/Navbars/DashboardNavbar/styles";
import DefaultNavbarLink from "common/Navbars/DefaultNavbar/DefaultNavbarLink";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setDirection,
} from "context";
import Configurator from "common/Configurator";
import { Divider, Grid, Typography } from "@mui/material";
import Notifications from "layouts/notifications";
import { UserAuth } from "context/AuthContext";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import PublicIcon from "@mui/icons-material/Public";
import { useTranslation } from "react-i18next";
import LockResetIcon from "@mui/icons-material/LockReset";

import burceMars from "assets/images/bruce-mars.jpg";

import { routes } from "../../../App";
import { getUserById } from "api_services/UserService";
import { getCurrentUser } from "api_services/UserService";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import { useRef } from "react";
import { getUsersCompanies } from "api_services/MemberService";
import secureLocalStorage from "react-secure-storage";

function DashboardNavbar({ absolute, light, isMini }) {
  const { user } = UserAuth();
  const [userData, setUserData] = useState([]);
  const userToken = secureLocalStorage.getItem("custom-token");

  ////console.log("User Data : ", userData);

  // const userData = localStorage.getItem("current-user-data");
  // const userAddress = localStorage.getItem("current-user-address");

  ////console.log("user access token : ", userToken);
  ////console.log("user refresh token : ", refreshToken);
  // localStorage.setItem("access-token", userToken);
  // localStorage.setItem("refresh-token", refreshToken);

  // async function getCusToken(userToken) {
  //   const response = await getCustomToken(userToken);

  //   if (response !== null) {
  //    //console.log("Custom Token : ", response.token);
  //     localStorage.setItem("custom-token", response.token);
  //   } else {
  //    //console.log("null response", response);
  //   }
  // }

  async function getCurUser() {
    //console.log("Custom Token in Get current user: ", userToken);
    const response = await getCurrentUser(userToken, false);
    if (response.id !== null && response.status !== 401 && response.status !== 500) {
      setUserData(response);
      return response.id;
    } else {
      //console.log(response);
      await logOut();
      navigate("/authentication/sign-in");
      //return 0;
    }
  }

  const [AllMemberData, setAllMemberData] = useState([]);

  async function getAllMemberList(currId) {
    const response = await getUsersCompanies(currId, userToken);
    if (response !== null) {
      setAllMemberData(response);
      secureLocalStorage.setItem("linked-user-companies", JSON.stringify(response));
      // setMemberAddress(response.address[0]);
    } else {
      //console.log(response);
    }
  }

  useEffect(() => {
    const updateLoggedUserCompanies = async () => {
      const currId = await getCurUser();
      getAllMemberList(currId);
    };
    updateLoggedUserCompanies();
  }, []);

  // const userToken = user.accessToken;
  // const [userData, setUserData] = useState({});

  // useEffect(() => {
  //   async function getUserByID() {
  //     const response = await getUserById(user.uid, userToken);

  //     if (response._id !== null) {
  //       setUserData(response);
  //     } else {
  //      //console.log(response);
  //     }
  //   }

  //   getUserByID();
  // }, []);

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenuTheme, setOpenMenuTheme] = useState(false);
  const [openMenuProfile, setOpenMenuProfile] = useState(false);
  const [openMenuLang, setOpenMenuLang] = useState(false);

  const route = useLocation().pathname.split("/").slice(1);
  const navigate = useNavigate();

  const { logOut } = UserAuth();
  const handleLogout = async () => {
    try {
      await logOut();
      //console.log("You are Logged Out !");
      navigate("/authentication/sign-in");
    } catch (e) {
      //console.log(e.message);
    }
  };

  // Changing the direction to rtl
  const changeDirection = () => {
    setDirection(dispatch, "rtl");

    return () => setDirection(dispatch, "ltr");
  };

  const changeDirectionBack = () => {
    setDirection(dispatch, "ltr");

    return () => setDirection(dispatch, "rtl");
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenuTheme = (event) => setOpenMenuTheme(event.currentTarget);
  const handleOpenMenuProfile = (event) => setOpenMenuProfile(event.currentTarget);
  const handleCloseMenuTheme = () => setOpenMenuTheme(false);
  const handleCloseMenuProfile = () => setOpenMenuProfile(false);
  const { t, i18n } = useTranslation();
  function handleLanguage(lang) {
    i18n.changeLanguage(lang);
    handleCloseMenuLang();
    changeDirectionBack();
  }

  function handleLanguageARA(lang) {
    i18n.changeLanguage(lang);
    handleCloseMenuLang();
    changeDirection();
  }

  const handleOpenMenuLang = (event) => setOpenMenuLang(event.currentTarget);
  const handleCloseMenuLang = () => setOpenMenuLang(false);

  const renderMenuLang = () => (
    <Menu
      anchorEl={openMenuLang}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenuLang)}
      onClose={handleCloseMenuLang}
      sx={{ mt: 1 }}
    >
      <MDBox sx={{ borderBottom: "1px solid rgb(0, 0, 0, 0.1)" }}>
        <Button
          variant="none"
          sx={iconsStyle}
          color="inherit"
          startIcon={<PublicIcon />}
          onClick={() => handleLanguage("eng")}
        >
          ENG
        </Button>
      </MDBox>
      <MDBox sx={{ borderBottom: "1px solid rgb(0, 0, 0, 0.1)" }}>
        <Button
          variant="none"
          sx={iconsStyle}
          color="inherit"
          startIcon={<PublicIcon />}
          onClick={() => handleLanguage("ger")}
        >
          DEU
        </Button>
      </MDBox>
      <MDBox>
        <Button
          variant="none"
          sx={iconsStyle}
          color="inherit"
          startIcon={<PublicIcon />}
          onClick={() => handleLanguageARA("ara")}
        >
          عربي
        </Button>
      </MDBox>
    </Menu>
  );

  // Render the notifications menu
  const renderMenuTheme = () => (
    <Menu
      anchorEl={openMenuTheme}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenuTheme)}
      onClose={handleCloseMenuTheme}
      sx={{ mt: 1 }}
    >
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert severity="error">This is an error alert — check it out!</Alert>
        <Alert severity="warning">This is a warning alert — check it out!</Alert>
        <Alert severity="info">This is an info alert — check it out!</Alert>
        <Alert severity="success">This is a success alert — check it out!</Alert>
      </Stack>
    </Menu>
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  // const open = Boolean(anchorEl);
  const handleMyCompanyClick = (event) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };
  // const handleMyCompanyClose = () => {
  //   setAnchorEl(null);
  // };

  const renderMyCompanies = AllMemberData.map((data, index) => {
    const navigateToCompany = () => {
      navigate(`/directory/profile/${data.id}`);
      // window.location.reload(false);
    };
    return (
      <NotificationItem
        onClick={() => navigateToCompany()}
        icon={
          <MDAvatar
            src={!data.companyLogo ? "./placeholder.jpg" : data.companyLogo}
            alt={data.companyName}
            size="sm"
            variant="square"
            sx={({ borders: { borderWidth }, palette: { white } }) => ({
              border: `${borderWidth[2]} solid ${white.main}`,
              cursor: "pointer",
              position: "relative",
              borderRadius: 3,

              "&:hover, &:focus": {
                zIndex: "10",
              },
            })}
          />
        }
        title={data.companyName}
      />
      // <MenuItem key={data.id}>
      //   <MDBox display="flex" alignItems="center" onClick={() => navigateToCompany()}>
      //     <MDAvatar
      //       src={!data.companyLogo  ? "./placeholder.jpg" : data.companyLogo}
      //       alt={data.companyName}
      //       size="sm"
      //       variant="square"
      //       sx={({ borders: { borderWidth }, palette: { white } }) => ({
      //         border: `${borderWidth[2]} solid ${white.main}`,
      //         cursor: "pointer",
      //         position: "relative",
      //         borderRadius: 3,

      //         "&:hover, &:focus": {
      //           zIndex: "10",
      //         },
      //       })}
      //     />
      //     <MDBox display="flex" flexDirection="column">
      //       <MDTypography variant="button" ml={2}>
      //         {data.companyName.slice(0, 20) + "..."}
      //       </MDTypography>
      //     </MDBox>
      //   </MDBox>
      // </MenuItem>
    );
  });

  const renderMenuProfile = () => (
    <Menu
      anchorEl={openMenuProfile}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenuProfile)}
      onClose={handleCloseMenuProfile}
      sx={{ mt: 1 }}
    >
      <Grid container spacing={2} alignItems="center" px={1} pt={1}>
        <Grid item>
          <MDAvatar
            src={!userData.avatar ? "./placeholder.jpg" : userData.avatar}
            alt="profile-image"
            size="md"
            shadow="sm"
          />
        </Grid>
        <Grid item>
          <MDBox height="100%" mt={0.5} lineHeight={1}>
            <MDTypography variant="h6" fontWeight="medium">
              {userData && userData.displayName}
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="regular">
              {userData && userData.email}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
      <Divider />
      <Link to={`/contacts/profile/${userData && userData.id}`}>
        <NotificationItem
          icon={
            <Icon>
              <AccountCircleIcon />
            </Icon>
          }
          title={t("topnavbar.profile")}
        />
      </Link>
      <div
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleMyCompanyClick}
      >
        <NotificationItem
          icon={
            <Icon>
              <ApartmentIcon />
            </Icon>
          }
          title={"My Companies"}
        />
      </div>
      {/* <div>
        <Menu
          sx={{ ml: { xs: 0, sm: 0.5 }, mt: { xs: 0, sm: 1 }, maxHeight: 200 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMyCompanyClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {renderMyCompanies}
        </Menu>
      </div> */}
      {open ? (
        AllMemberData.length > 0 ? (
          renderMyCompanies
        ) : (
          <MDBox lineHeight={1}>
            <MDTypography
              display="flex"
              justifyContent="center"
              variant="button"
              color="text"
              fontWeight="light"
              textTransform="capitalize"
            >
              No linked offices
            </MDTypography>
          </MDBox>
        )
      ) : null}

      {/* <Link to="">
        <NotificationItem
          icon={
            <Icon>
              <SettingsIcon />
            </Icon>
          }
          title={t("topnavbar.settings")}
        />
      </Link> */}
      <Divider />
      <Link to="/change-password">
        <NotificationItem
          icon={
            <Icon>
              <LockResetIcon />
            </Icon>
          }
          title={"Change Password"}
        />
      </Link>
      <Link to="" onClick={handleLogout}>
        <NotificationItem
          icon={
            <Icon>
              <LogoutIcon />
            </Icon>
          }
          title={t("topnavbar.logout")}
        />
      </Link>
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        {/* <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={t(`sidebar.${route[route.length - 1]}`)} route={route} light={light} />
        </MDBox> */}

        <MDBox display="flex" justifyContent="end" sx={{ width: "100%" }}>
          {/* {isMini ? null : ( */}
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox display="flex" justifyContent="end" alignItems="center" sx={{ width: "100%" }}>
              {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}

              {/* <Button
                variant="none"
                startIcon={<Brightness4Icon />}
                sx={iconsStyle}
                color="inherit"
              >
                {t("topnavbar.mode")}
                <Configurator />
              </Button> */}

              {userData !== null && userData.displayName != null ? (
                <MDTypography variant="button" fontWeight="bold" mr={1.5}>
                  Welcome, {userData && userData.displayName}
                </MDTypography>
              ) : null}

              {/* <IconButton
                size="medium"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleOpenMenuTheme}>
                <Brightness4Icon sx={iconsStyle}/>
              </IconButton>
              {renderMenuTheme()} */}

              {/* <IconButton
                size="medium"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleOpenMenuLang}
              >
                <LanguageIcon sx={iconsStyle} />
              </IconButton> */}
              {renderMenuLang()}

              {/* <IconButton
                size="medium"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleOpenMenuTheme}
              >
                <CircleNotificationsIcon sx={iconsStyle} />
              </IconButton> */}
              {renderMenuTheme()}

              {/* <IconButton
                sx={navbarIconButton}
                size="medium"
                disableRipple
                color="inherit"
                onClick={handleOpenMenuProfile}
              >
                <AccountCircleIcon sx={iconsStyle} />
              </IconButton> */}

              <MDAvatar
                src={!userData.avatar ? "./placeholder.jpg" : userData.avatar}
                alt="profile-image"
                size="xs"
                shadow="sm"
                onClick={handleOpenMenuProfile}
                sx={{ cursor: "pointer" }}
              />
              {renderMenuProfile()}

              <MDBox color={light ? "white" : "inherit"}>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  {miniSidenav ? (
                    <MenuOpenIcon sx={iconsStyle} fontSize="large" />
                  ) : (
                    <MenuIcon sx={iconsStyle} fontSize="large" />
                  )}
                </IconButton>
              </MDBox>
            </MDBox>
          </MDBox>
          {/* )} */}
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
