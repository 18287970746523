// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid, Icon } from "@mui/material";
import FavouriteIcon from "common/Favourite";
import flameIcon from "assets/images/fire.png";
import greenArrowIcon from "assets/images/green-arrow.png";
import blueArrowIcon from "assets/images/blue-arrow.png";

function PartnerCard({
  id,
  companyName,
  action,
  matchCategory,
  city,
  country,
  isFavourite,
  refresh,
  memberId,
  priority,
}) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
        boxShadow: ({ boxShadows: { md } }) => md,
        px: 2,
        py: 2,
        height: "100%",
        width: "100%",
      }}
    >
      <Grid
        container
        spacing={1}
        display="flex"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          xl={7}
          pt={0}
          pl={0}
          display="flex"
          alignItems="start"
          component={Link}
          to={action.route}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div lineHeight={0}>
              <MDBox sx={{ mt: { xs: -1, sm: -1, md: -1, lg: -1, xl: -1 } }}>
                {action.type === "internal" ? (
                  <MDTypography
                    component={Link}
                    to={action.route}
                    variant="h6"
                    textTransform="capitalize"
                    color="black"
                  >
                    {companyName}
                  </MDTypography>
                ) : (
                  <MDTypography
                    component="a"
                    href={action.route}
                    target="_blank"
                    rel="noreferrer"
                    variant="h6"
                    textTransform="capitalize"
                    color="black"
                  >
                    {companyName}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mt={0} lineHeight={0}>
                <MDTypography variant="button" fontWeight="bold" color="primary">
                  {city + ", " + country}
                </MDTypography>
              </MDBox>
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={1}
          xl={1}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{ flexDirection: { md: "column", sm: "row" } }}
        >
          <MDBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{ justifyContent: { md: "end", sm: "start" } }}
          >
            <img
              height="20px"
              src={
                matchCategory === "mutual_interest"
                  ? flameIcon
                  : matchCategory === "partner_interest"
                    ? blueArrowIcon
                    : matchCategory === "member_interest"
                      ? greenArrowIcon
                      : ""
              }
              title={
                matchCategory === "mutual_interest"
                  ? "Perfect Match"
                  : matchCategory === "partner_interest"
                    ? "Have Shown Interest in Your Location"
                    : matchCategory === "member_interest"
                      ? "Located in a Region You Are Interested In"
                      : ""
              }
              alt="Partner Interest icon"
              style={{ cursor: "help" }}
            />
          </MDBox>

          <MDBox sx={{ width: "36px" }}>
            <FavouriteIcon isFavourite={isFavourite} memberId={id} refresh={refresh} />
          </MDBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the DefaultProjectCard
PartnerCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default PartnerCard;
