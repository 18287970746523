import * as React from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import { Alert, Card, CircularProgress, Dialog, Skeleton, Snackbar, Stack } from "@mui/material";
import MDInput from "components/MDInput";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import { uploadDocument } from "api_services/UserService";
import secureLocalStorage from "react-secure-storage";
import { GENERAL_CONSTS } from "utils/constants/general.constants";
import { editOptionSchema } from "layouts/validationSchema/editOptionSchema";
import { useNavigate, useParams } from "react-router-dom";
import { getOptionById } from "api_services/OptionService";
import { updateOptionById } from "api_services/OptionService";
import { useEffect } from "react";

function EditOption() {
  const userToken = secureLocalStorage.getItem("custom-token");

  const [saving, setSaving] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState(null);
  const { id } = useParams();
  const [optionData, setOptionData] = useState({});
  const [positionState, setPositionState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = positionState;
  const handleAlertClose = () => {
    setPositionState({ ...positionState, open: false });
  };
  const navigate = useNavigate();

  async function updateOption(data) {
    setLoadingOpen(true);
    setSaving(true);

    const response = await updateOptionById(id, data, userToken);
    if (response == "ok") {
      navigate(-1);
    } else {
      setLoadingOpen(false);
      setSaving(false);
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
    }
  }

  useEffect(() => {
    async function getOption() {
      const response = await getOptionById(id, userToken);

      if (response !== null) {
        setOptionData(response);
        setIsLoading(false);
      } else {
        //console.log(response);
        setIsLoading(true);
      }
    }
    getOption();
  }, []);

  async function uploadNewDocument(file) {
    //console.log("file in downloads : ", file);
    setLoadingOpen(true);
    setSaving(true);
    const response = await uploadDocument(
      file,
      GENERAL_CONSTS.ICONS_ROOT_FOLDER,
      userToken,
      null,
      null,
      null
    );
    if (response?.fileDownloadUri) {
      const downloadUri = response.fileDownloadUri;
      return downloadUri.substring(0, downloadUri.indexOf("?"));
    } else {
      setLoadingOpen(false);
      setSaving(false);
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
      //console.log("file upload failed");
    }
  }

  const {
    getRootProps: getRootFileProps,
    getInputProps: getInputFileProps,
    acceptedFiles,
  } = useDropzone({
    accept: [".doc", ".docx", "application/pdf", ".xls", ".xlsx", ".pptx"],
    onDrop: (acceptedFile) => {
      setImageUrl(URL.createObjectURL(acceptedFile[0]));
      setFieldValue("file", acceptedFile[0]);
      setUploadFile(acceptedFile[0]);
    },
  });

  const iconPreview = acceptedFiles.map(() => (
    <MDBox
      mt={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ width: { xs: "50%", sm: "30%", md: "15%" } }}
    >
      <img src={imageUrl} alt={imageUrl} width="100%" />
    </MDBox>
  ));

  const onSubmit = async () => {
    try {
      let iconDownloadUri = null;
      if (uploadFile.length == undefined) {
        iconDownloadUri = await uploadNewDocument(uploadFile);
      }
      const optionObj = {
        name: values.name,
        icon: iconDownloadUri === null ? optionData.icon : iconDownloadUri,
        category: optionData.category,
        description: "",
      };
      updateOption(optionObj);
    } catch (e) {
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
      //console.log(e.message);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        name: optionData.name,
        file: null,
      },

      validationSchema: editOptionSchema,
      onSubmit,
    });

  //////////////////////////

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox />
      <Card sx={{ p: 3, mb: 5 }}>
        <MDBox>
          <Grid
            container
            pt={0}
            lineHeight={1.25}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} md={12} xl={12}>
              <MDTypography variant="h5" fontWeight="medium" color="black">
                Edit Option
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>

        {isLoading ? (
          <Stack spacing={3} sx={{ mt: 4 }}>
            {/* For other variants, adjust the size with `width` and `height` */}
            <Skeleton variant="rounded" width={"100%"} height={100} />
            <Skeleton variant="rounded" width={"100%"} height={70} />
            <Skeleton variant="rectangular" width={"100%"} height={50} />
            <Skeleton variant="rectangular" width={"100%"} height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={10} />
          </Stack>
        ) : (
          <>
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              component="form"
              role="form"
              onSubmit={handleSubmit}
            >
              <Grid item xs={12} md={12} xl={12} pt={5}>
                <MDBox mb={2}>
                  <MDInput
                    error={errors.name && touched.name && true}
                    focused={errors.name && touched.name && true}
                    label={errors.name && touched.name ? errors.name : "Name"}
                    type="text"
                    fullWidth
                    id="name"
                    onChange={handleChange}
                    value={values.name}
                    onBlur={handleBlur}
                  />
                </MDBox>

                <MDBox mb={1}>
                  <MDTypography
                    variant="button"
                    color="black"
                    fontWeight="regular"
                    textTransform="capitalize"
                    ml={1.7}
                  >
                    Upload Icon
                  </MDTypography>
                  {errors.file && touched.file && (
                    <MDBox pl={1.5}>
                      <MDTypography variant="button" color="error" sx={{ fontSize: 9.6 }}>
                        {errors.file}
                      </MDTypography>
                    </MDBox>
                  )}
                </MDBox>

                <MDBox>
                  <MDBox
                    {...getRootFileProps({ className: "dropzone" })}
                    variant="outlined"
                    color={errors.file && touched.file ? "error" : "text"}
                    height="100%"
                    sx={{
                      border: errors.file && touched.file ? "1.6px solid" : "1.6px dotted",
                      borderColor: "text",
                      borderRadius: "5px",
                    }}
                    py={3}
                    px={8}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <MDBox>
                      <input {...getInputFileProps()} />
                      <MDTypography color="text" sx={{ cursor: "pointer" }} variant="button">
                        Drop files here, or Click to select files
                      </MDTypography>
                    </MDBox>
                  </MDBox>

                  <MDBox mt={0} display="flex" alignItems="center" justifyContent="center">
                    {errors.file && touched.file ? "" : iconPreview}
                  </MDBox>
                </MDBox>
              </Grid>

              <Grid item xs={12} md={12} xl={12} mb={2}>
                {/* <MDBox mx={0}>
                <MDBox mb={1} ml={1.7}>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                      textTransform="capitalize"
                    >
                      Upload Icon
                    </MDTypography>
                    {errors.picture && touched.picture && (
                      <MDBox>
                        <MDTypography variant="button" color="error" sx={{ fontSize: 9.6 }}>
                          {errors.picture}
                        </MDTypography>
                      </MDBox>
                    )}
                  </MDBox>

                  <MDBox
                    {...getRootLogoProps({ className: "dropzone" })}
                    variant="outlined"
                    sx={{
                      border: errors.picture && touched.picture ? "1.6px solid" : "1.6px dotted",
                      borderColor: "text",
                      borderRadius: "5px",
                    }}
                    color={errors.picture && touched.picture ? "error" : "text"}
                    py={3}
                    px={5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <MDBox>
                      <input {...getInputLogoProps()} />
                      <MDTypography color="text" sx={{ cursor: "pointer" }} variant="button">
                        Drop files here, or Click to select files
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  {errors.picture && touched.picture ? "" : fileAcceptedItems}
                </MDBox> */}

                <MDBox mx={0} mt={3} display="flex" justifyContent="end">
                  <MDButton type="submit" to="" size="medium" color="primary">
                    Update Option
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </>
        )}
        <Divider />

        {/* {files.length !== 0 ? (
          <Grid container px={0} display="flex" justifyContent="space-between">
            {Documents}
          </Grid>
        ) : (
          <Grid my={20}>
            <MDTypography variant="text" color="text" display="flex" justifyContent="center" mb={2}>
              No Downloads found !
            </MDTypography>
          </Grid>
        )} */}
      </Card>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          Option creation failed!
        </Alert>
      </Snackbar>

      {/* Dialog Popup //////////////////////////////////////////// */}

      <MDBox sx={{ overflowY: "visible" }}>
        <Dialog
          open={loadingOpen}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          sx={{ overflowY: "visible" }}
        >
          <MDBox mb={1} mt={2} sx={{ mx: { xs: 5, md: 10 } }} textAlign="center">
            <MDTypography display="block" variant="button" color="primaryDark">
              Updating option ...
            </MDTypography>
            {saving && <CircularProgress sx={{ mt: 2 }} />}
          </MDBox>
        </Dialog>
      </MDBox>

      {/* //////////////////////////////////////////// */}

      <Footer />
    </DashboardLayout>
  );
}

export default EditOption;
