import { StarBorderRounded, StarRounded } from "@mui/icons-material";
import { CircularProgress, Dialog, IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { updateUserFavourites } from "api_services/UserService";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import secureLocalStorage from "react-secure-storage";
import { useState } from "react";

export default function FavouriteIcon({ memberId, isFavourite, refresh }) {
  const userToken = secureLocalStorage.getItem("custom-token");
  const currentUser = JSON.parse(secureLocalStorage.getItem("current-user-data"));
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [favourite, setFavourite] = useState(isFavourite);
  const [positionState, setPositionState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = positionState;

  const toggleFavourite = async () => {
    setLoadingOpen(true);
    setSaving(true);

    const response = await updateUserFavourites(currentUser.id, memberId, userToken);
    if (!refresh) {
      setLoadingOpen(false);
      setSaving(false);
    }
    if (response.id !== null) {
      secureLocalStorage.setItem("user-favourites", JSON.stringify(response.favouriteCompanies));
      if (refresh) {
        window.location.reload();
      } else {
        setFavourite(!favourite);
      }
    } else {
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
    }
  };
  const handleAlertClose = () => {
    setPositionState({ ...positionState, open: false });
  };

  return (
    <>
      <IconButton
        size="small"
        disableRipple
        color={favourite ? "primary" : "grey"}
        onClick={toggleFavourite}
      >
        {favourite ? <StarRounded fontSize="large" /> : <StarBorderRounded fontSize="large" />}
      </IconButton>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          Failed to add to favourites!
        </Alert>
      </Snackbar>
      <MDBox sx={{ overflowY: "visible" }}>
        <Dialog
          open={loadingOpen}
          aria-labelledby="responsive-dialog-title"
          sx={{ overflowY: "visible" }}
        >
          <MDBox mb={1} mt={2} sx={{ mx: { xs: 5, md: 10 } }} textAlign="center">
            <MDTypography display="block" variant="button" color="primaryDark">
              Updating Favourites ...
            </MDTypography>
            {saving && <CircularProgress sx={{ mt: 2 }} />}
          </MDBox>
        </Dialog>
      </MDBox>
    </>
  );
}
