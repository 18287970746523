import * as yup from "yup";

const fileNameRules = /^[A-Za-z0-9\-_ ]+$/;

export const editOptionSchema = yup.object().shape({
  name: yup
    .string()
    .matches(fileNameRules, {
      message: "This field can only include letters, numbers, dashes and underscores",
    })
    .max(254, "Content is too large")
    .required("This field is required"),
  file: yup
    .mixed()
    .test("fileSize", "Please upload a file below 50MB.", (value) => {
      if (value) {
        return value.size <= 50000000;
      }
      return true;
    }),
});
